import {
  postBodyRequest, getRequest
} from '@/utils/axios'

export function weixinUpload (formData) {
  return postBodyRequest('/cis/crm/daily/wx/upload', formData)
}
export function weixinUploadVideo (formData) {
  return postBodyRequest('/cis/crm/daily/wx/uploadVideo', formData)
}

export function add (params) {
  return postBodyRequest('/cis/crm/daily/add', params)
}

export function page (params) {
  return postBodyRequest('/cis/crm/daily/page', params)
}

export function send (id) {
  return getRequest(`/cis/crm/daily/send/${id}`)
}
export function deleteReport (id) {
  return postBodyRequest(`/cis/crm/daily/wx/delete/${id}`, null)
}
