<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #toolbarRight>
      <a-button type="primary" @click="visible = true">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <a-table :dataSource="dataSource" :columns="columns" :rowKey="(record,index)=>{return index}" :pagination="pagination" :loading="loading"  @change="handleTableChange">
      <template #fileUrl="{record }">
        <a-image v-if="record.fileId !== null&&record.fileType.value===0" :width="80" :src="record.url" />
        <video v-if="record.fileId !== null&&record.fileType.value===1" style="width: 100px;height:100px;" :src="record.url"></video>
      </template>
      <template #status="{ record }">
        <a v-if="record.status">已发送</a>
        <a v-else>未发送</a>
      </template>
      <template #operation="{ record }">
<!--         <a-popconfirm title="确认手动发送吗?" @confirm="send(record.id)" v-show="false">-->
<!--          <a>手动发送</a>-->
<!--        </a-popconfirm>-->
<!--        <a-divider type="vertical" />-->
        <a-popconfirm title="确认要删除吗?" @confirm="deleteMsg(record.id)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="visible" title="添加新任务" @ok="addForm" :maskClosable="false">
    <a-form :model="formState" layout="vertical">
      <a-form-item label="填写文案" :wrapperCol="{flex:'auto'}">
        <a-textarea v-model:value="formState.content" placeholder="请输入分享文案" />
      </a-form-item>
      <a-form-item label="选择上传方式" :wrapperCol="{flex:'auto'}">
        <a-radio-group v-model:value="formState.fileType">
          <a-radio-button :value="0">图片</a-radio-button>
          <a-radio-button :value="1">视频</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label="formState.fileType===0?'上传图片 (最大10M)':'上传视频 (最大10M)'" :wrapperCol="{flex:'auto'}">
        <a-upload v-model::file-list="fileList" :remove="handleRemove" list-type="picture-card" :before-upload="beforeUpload">
          <div v-if="fileList.length < 1">
            <plus-outlined />
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="设置时间" :wrapperCol="{flex:'auto'}">
        <a-date-picker show-time placeholder="选择日期" v-model:value="formState.releaseTime" valueFormat="YYYY-MM-DD HH:mm:ss" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" @click="onNo">取消</a-button>
      <a-button key="submit" type="primary" :loading="addFormLoading" @click="addForm">确认</a-button>
    </template>
  </a-modal>
</template>
<script>
import SuyTable from '@/components/SuyTable'
import { reactive, toRefs } from 'vue'
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { weixinUpload, weixinUploadVideo, add, page, send as sendImage, deleteReport } from '@/api/crm/dailyPoster'
import { message } from 'ant-design-vue'
import { fileUpload as uploadBpm } from '@/api/marketing/modList'

export default {
  components: {
    SuyTable,
    PlusCircleOutlined,
    PlusOutlined
  },
  setup () {
    const state = reactive({
      formState: {
        fileType: 0
      },
      dataSource: [],
      columns: [
        {
          title: '发布图片',
          dataIndex: 'fileUrl',
          key: 'fileUrl',
          width: '100px',
          slots: { customRender: 'fileUrl' }
        },
        {
          title: '发布文案',
          dataIndex: 'content',
          key: 'content'
        },
        {
          title: '上传时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '操作人',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '发送状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' }
        },
        {
          title: '发送内容',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '设定发布时间',
          dataIndex: 'releaseTime',
          key: 'releaseTime'
        },
        { title: '操作', dataIndex: 'operation', width: 200, slots: { customRender: 'operation' } }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      fileList: [],
      loading: false,
      visible: false,
      addFormLoading: false,
      wxT: '',
      media_id: ''
    })

    const addForm = () => {
      state.addFormLoading = true
      const formData = new FormData()
      state.fileList.forEach(file => {
        formData.append('file', file)
      })
      onAdd(formData, null)
      // if (state.formState.fileType === 0) {
      //   weixinUpload(formData).then(res => {
      //     if (res.code === 10000) {
      //       onAdd(formData, res)
      //     }
      //   })
      // } else {
      //   weixinUploadVideo(formData).then(res => {
      //     if (res.code === 10000) {
      //       onAdd(formData, res)
      //     }
      //   })
      // }
    }
    const onAdd = (formData, data) => {
      // state.formState.wxFileId = data.data

      uploadBpm(formData).then(res => {
        if (res.code === 10000) {
          state.formState.fileId = res.data.id
          state.formState.fileUrl = res.data.url
          // formData.append('content', state.formState.content)
          // formData.append('fileType', state.formState.fileType)
          // formData.append('releaseTime', state.formState.releaseTime)
          // formData.append('fileId', res.data.id)
          add({ ...state.formState }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.visible = false
              loadData()
            }
          }).catch(err => {
            console.log(err)

          }).finally(() => {
            state.addFormLoading = false
          })
        }
      }).catch(err => {
        console.log(err)
        state.addFormLoading = false
      }).finally(() => {
        state.addFormLoading = false
      })

    }
    const onNo = () => {
      state.visible = false
    }
    const loadData = () => {
      state.loading = true
      page({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const send = (id) => {
      state.loading = true
      sendImage(id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const handleRemove = file => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
    }

    const beforeUpload = file => {
      state.fileList = []
      state.fileList = [file]
      return false
    }
    const deleteMsg = (id) => {
      deleteReport(id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).finally(() => {
      })
    }
    loadData()
    // 表格分页
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    return {
      ...toRefs(state),
      loadData,
      handleRemove,
      beforeUpload,
      handleTableChange,
      addForm,
      send,
      deleteMsg,
      onNo
    }
  }
}
</script>

<style>
</style>
